import { translate } from '@/messages'

export default [
  {
    text: translate('base.id'),
    align: 'start',
    value: 'id'
  },
  {
    text: translate('verification.organization'),
    align: 'start',
    value: 'organization',
    sortable: false
  },
  {
    text: translate('verification.user'),
    align: 'start',
    value: 'user',
    sortable: false
  },
  {
    text: translate('verification.passport'),
    align: 'start',
    value: 'passport',
    sortable: false
  },
  {
    text: translate('verification.created_at'),
    align: 'start',
    value: 'created_at'
  },
  {
    text: translate('verification.updated_at'),
    align: 'start',
    value: 'updated_at'
  },
  {
    text: translate('verification.status'),
    align: 'start',
    value: 'status'
  }
]
