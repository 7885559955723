<template>
  <div>
    <BaseList
        v-model="selectedItems"
        :headers="headers"
        :query="{sortBy: ['id'], sortDesc: [true]}"
        item-key="properties.id"
        :can-create="false"
    >
      <template v-slot:item="{item}">
        <ListItem :item="item" />
      </template>
    </BaseList>
  </div>
</template>

<script>

import ListItem from './list/ListItem'
import BaseList from '@/components/base/BaseList'
import headers from '@/components/views/account/verification/list/headers'
import BaseHeader from '@/components/base/BaseList/BaseHeader'
import BaseTable from '@/components/base/BaseTable'
import formatters from '@/components/mixins/formatters'
import watchFullPath from '@/components/mixins/watchFullPath'

export default {
  name: 'List',
  components: { BaseHeader, ListItem, BaseList, BaseTable },
  mixins: [formatters, watchFullPath],
  props: {
    canClick: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      headers: headers,
      selectedItems: []
    }
  },
  methods: {
 // атрибут :custom-sort="sort" для таблицы, нужно будет проверить потом(когда будет больше данных) работает ли сортировка по статусу верификации без этого
    sort: function(items, index) {//удалить, если не пригодится
      items.sort((a, b) => {
        if (index[0] === 'status') {
          return b.status.value - a.status.value
        }
      });
      return items;
    }
  }
}
</script>