<template>
  <tr v-if="item.organization" @click="handleClick(item.id)">
    <td>{{item.id}}</td>
    <td>{{ item.organization.name }}</td>
    <td>
      <p class="ma-0">{{ $t('verification.name') }}: {{ item.user.username }}</p>
      <p class="ma-0">{{ $t('verification.mail') }}: {{ item.user.email }}</p>
      <p class="ma-0">{{ $t('verification.phone') }}: {{ item.user.phone }}</p>
    </td>
    <td>
      <p class="ma-0">{{ $t('verification.title') }}: {{ item.passport.properties.object_name }}</p>
      <p class="ma-0">{{ $t('verification.location') }}: {{ item.passport.properties.territorial_affiliation }}</p>
    </td>
    <td>{{ timestampToDatetime(item.created_at) }}</td>
    <td>{{ timestampToDatetime(item.updated_at) }}</td>
    <td :class="statusData[item.status].color + '--text' + ' font-weight-bold'">{{ statusData[item.status].title }}</td>
  </tr>
</template>

<script>
import formatters from '@/components/mixins/formatters'
import listItem from '@/components/mixins/listItem'

export default {
  mixins: [formatters, listItem],
  name: 'ListItem',
  data () {
    return {
      statusData: {
        1: {
          color: 'orange',
          title: 'Ожидание верификации'
        },
        2: {
          color: 'red',
          title: 'Отклонено'
        },
        3: {
          color: 'green',
          title: 'Подтверждено'
        },
        4: {
          color: 'blue',
          title: 'В процессе'
        },
        5: {
          color: 'red',
          title: 'Ошибка верификации'
        }
      }
    }
  },
  methods: {
    handleClick (id) {
      if (this.$route.name === 'verification') {
        this.detail(id)
      } else if (this.$route.name === 'map') {
        this.$emit('click', id)
      } else {
        return true
      }
    }
  }
}
</script>
